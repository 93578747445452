import React, { Component } from 'react'
import './NotFoundPage.css'
export default class NotFoundPage extends Component {
    render() {
        return (
            <div>
                <h1 className="no-page-found">Opps!! No page found</h1>
            </div>
        )
    }
}
